$lib.ready(() => {
    'use strict';
    document.querySelectorAll(".publication-item a.bibtex").forEach(a => {
        // Load and show BibTeX
        let parentItem = $lib.parent(a, ".publication-item");
        const bibtexURL = a.href;
        let clicked = false;
        let successful = false;
        a.addEventListener('click', event => {
            if (clicked && !successful) {
                // Follow the link
                return;
            }

            event.preventDefault();
            event.stopPropagation();

            if (successful) {
                // Do nothing
                return;
            }

            clicked = true;

            let collapseItem = $lib.parent(a, ".collapse-item");
            let spinner = document.createElement('div');
            spinner.classList.add("spinner-donut");
            parentItem.appendChild(spinner);

            window.setTimeout(() => {
                fetch(bibtexURL)
                    .catch(() => window.location = bibtexURL)
                    .then(response => response.text())
                    .then(responseText => {
                        let bibtex = parentItem.querySelector('.bibtex.toggle');
                        if (bibtex == null) {
                            bibtex = $lib.createElementFromHTML('<div class="bibtex toggle">' +
                                '<pre class="bibtex">Loading...</pre>' +
                                '<span class="close" aria-label="Close" aria-role="button" title="Close">' +
                                '</span></div>');
                            parentItem.appendChild(bibtex);
                        }
                        $lib.hide(bibtex);
                        bibtex.querySelector(".bibtex").innerText = responseText;
                        $lib.slideDown(bibtex);
                        successful = true;
                        bibtex.querySelector('.close').addEventListener('click', () => {
                            clicked = false;
                            successful = false;
                            $lib.slideUp(bibtex);
                        });
                    })
                    .finally(() => spinner.remove());
            }, 500);
        });
    });

    document.querySelectorAll(".publication-list-headline.collapse").forEach(headline => {
        let handle = headline.querySelector('.collapse-handle');
        if (!handle.classList.contains('collapsed')) {
            handle.classList.add('notransition');
            let item = document.getElementById(handle.dataset.collapseItem);
            $lib.hide(item);
            handle.classList.add('collapsed');
            void(handle.offsetHeight);
            handle.classList.remove('notransition');
        }
    });
});
